import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion/'

import { Typography, Grid, Box, StepLabel, StepContent } from '@mui/material'

import { isRequired } from '@front/squirtle/utils/validators'
import CPUtils from '@front/squirtle/utils/CPUtils'

import { TextField, SingleOptionSelectorField, MenuSelectorField, ModelMenuSelectorField, ModelAutocompleteField } from '@front/squirtle/'

const Row1 = ({ companycontract_id, applicable_load, isTaxicounter, authorized_formulas }) => {
  return (
    <Grid container spacing={1} alignItems='flex-end'>
      <Grid item xs={2}>
        <TextField
          name={'name'}
          label={I18n.t('name.label', { count: 1 })}
          required
          validate={isRequired}
        />
      </Grid>
      <Grid item xs={2}>
        <ModelMenuSelectorField
          name='mode'
          model_name='ridemode'
          label={I18n.t('ride.label', { count: 1 })}
          labelKeys={['name_translated']}
          searchMode='search'
          config={{
            forced_filter: { enabled: true },
            sort: ['display_index ASC']
          }}
          loadOnMount
          selectFirst
          noneFirst
          noneLabel={I18n.t('all')}
        />
      </Grid>
      <Grid item xs={2}>
        <SingleOptionSelectorField
          name='active'
          optionLabel={I18n.t('active')}
        />
      </Grid>
      <Grid item xs={2}>
        <MenuSelectorField
          name={'priority'}
          options={[
            { label: '0', value: 0 },
            { label: '1', value: 1 },
            { label: '2', value: 2 },
            { label: '3', value: 3 },
            { label: '4', value: 4 },
            { label: '5', value: 5 },
            { label: '6', value: 6 },
            { label: '7', value: 7 },
            { label: '8', value: 8 },
            { label: '9', value: 9 }
          ]}
          label={I18n.t('priority.label', { count: 1 })}
          required
        />
      </Grid>
      {!isTaxicounter && <Grid item xs={2}>
        <MenuSelectorField
          name='accuracy'
          selectFirst
          options={[
            { label: I18n.t('pricerule.accuracy.high'), value: 'high' },
            { label: I18n.t('pricerule.accuracy.low'), value: 'low' }
          ]}
          label={I18n.t('pricerule.accuracy.label')}
          required
        />
      </Grid>}
      <Grid item xs={2}>
        <MenuSelectorField
          label={I18n.t('load.label', { count: 1 })}
          name={'applicable_load'}
          selectFirst
          options={[
            { label: I18n.t('load.user.label.other'), value: 'user' },
            { label: I18n.t('load.package.label.other'), value: 'package' }
          ]}
        />
      </Grid>
      <Grid item xs={12}>
        <ModelAutocompleteField
          name={'commercial_packages'}
          label={I18n.t('commercialpackage.label', { count: 2 })}
          model_name='commercialpackage'
          matchKeys={['commercialpackage', 'companycontract']}
          searchMode='search'
          searchQuery={'startsWith'}
          autocompleteKey={'name_trkey.fr'}
          config={{
            preFilter: useCallback(cp => cp.load_type === applicable_load, [applicable_load]),
            mutator: cp => ({
              ...CPUtils.getTranslatedLabel(cp),
              ...cp,
              commercialpackage: _.get(cp, 'commercialpackage_id'),
              companycontract: companycontract_id
            }),
            forced_filter: { authorized_formulas },
            watchers: [authorized_formulas],
          }}
          selectFirst={false}
          loadOnMount
          multiple
          required
        />
      </Grid>
    </Grid>
  )
}

const PrimarySection = ({ formattedSuspension, ...props }) => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('info.general')}</Typography>
  </StepLabel>
  <StepContent >
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }}>
      <Row1 {...props} />
    </Box>
  </StepContent>
</>

export default React.memo(PrimarySection)
