import { FormatUtils } from '@front/volcanion/utils'

class Callbacks {
  static filterToFormHandler(extra_filter) {
    return function filterToForm(filter) {
      return {
        driver_id: extra_filter?.driver_id,
        do: {
          auth: {
            gsm: !!extra_filter?.client_phone ? `+${extra_filter?.client_phone}` : undefined
          }
        },
        operator: extra_filter?.operator_id,
        ...filter
      }
    }
  }
  static formToFilterHandler(codeIntlPhone) {
    return function formToFilter(values, extra, meta, state) {
      const { requestedAt_start, requestedAt_end, company, contract, service, commercial_formula, ...rest } = values
      const loadsType = _.get(rest, 'commercial_package.load_type')
      return _.merge({},
        { requestedAt: FormatUtils.intervalFilter(requestedAt_start, requestedAt_end) },
        !loadsType ? _.omit(rest, ['commercial_package.load_type']) : rest,
        !!company && { service: { contract: { company } } },
        !!contract && { service: { contract } },
        !!service && { service },
        !!commercial_formula && {
          or: [
            { do: { commercial_formula }, service: null },
            { service: { contract: { formula: commercial_formula } } }
          ]
        },
        _.has(rest, 'do.auth.gsm') ? { do: { auth: { gsm: FormatUtils.parsePhoneNumber(rest?.do?.auth?.gsm, `+${codeIntlPhone}`) } } } : {},
        _.has(rest, 'do.customerinfo.name') ? { do: { customerinfo: { name: { contains: rest?.do?.customerinfo?.name } } } } : {},
        _.has(rest, 'do.info.first_name') ? { do: { info: { first_name: { startsWith: rest?.do?.info?.first_name } } } } : {},
        _.has(rest, 'do.info.last_name') ? { do: { info: { last_name: { startsWith: rest?.do?.info?.last_name } } } } : {},
        _.has(rest, 'source.name') ? { source: { name: { contains: rest?.source?.name } } } : {},
        _.has(rest, 'loads.src_contact') ? { loads: { src_contact: { contains: rest?.loads?.src_contact } } } : {},
        _.has(rest, 'order_client_id') ? { order_client_id: { contains: rest?.order_client_id } } : {},
      )
    }
  }
}

export default Callbacks
