
import React from 'react'

import { Box } from '@mui/material'

import OrderForm from '@abra/frames/Order'
import WaitComponent from './waitComponent'

const OrderPage = props => {
  const {
    handleClickHistory,
    handleClickCreateOrder,
    handleClickReturnHome,
    ...rest
  } = props

  return (
    <Box>
      <WaitComponent {...rest} />
      <Box sx={{ pr: 6 }}>
        <OrderForm {...rest} disableHeader={true} />
      </Box>
    </Box>
  )
}

export default React.memo(OrderPage)
