import { hooks } from '@front/volcanion'
import React, { useState } from 'react'

const withContainer = Component => props => {
  const search_params = hooks.useSearchParams()
  const order_id = _.head(_.get(search_params, 'order_id'))

  const [isWaiting, setIsWaiting] = useState(!order_id)
  const isOrderDuplicate = !!order_id

  const mergedProps = {
    form_name: 'order_form',
    order_id,
    isOrderDuplicate,
    isWaiting,
    setIsWaiting
  }
  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
